.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.list {
  border: none;
  a {
    text-decoration: none; // Remove the default underline
    color: #fff; // Change the link text color
  }

  &.active-tab {
    // color: #fff; // Change the link text color on hover
    // background-color: #00000026;
    border-bottom: 3px solid #fff;
  }

  &:hover {
    color: #fff; // Change the link text color on hover
  }
}