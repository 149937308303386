// Here you can add other styles

.main {
    thead {
        tr {
            line-height: 44px;
            th {
                color: #666 !important;
                font-size: 14px;
                width: 80px;
                font-weight: 400;

                @media (min-width: 576px) {
                    display: revert;
                    line-height: unset;
                }
            }
            @media (max-width: 576px) {
                line-height: unset;
            }
        }
    }

    tbody {
        tr {
            line-height: 72px;
            td {
                @media (min-width: 576px) {
                    display: revert;
                }
                color: #333;
                div {
                    &.date::before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: red;
                        margin-right: 5px;
                        display: block;
                    }
                    &.ball span {
                        width: 48px;
                        height: 48px;
                        @media (max-width: 576px) {
                            width: 32px;
                            height: 32px;
                            line-height: 28px;
                            margin: 0 10px;
                        }
                        line-height: 44px;
                        text-align: center;
                        margin: 0 5px;
                        font-size: 24px;
                        font-weight: 700;
                        border-radius: 50%;
                        color: #333;
                        border: 2px solid #ccc;
                    }
                }
                @media (max-width: 576px) {
                    font-size: 14px;
                }
            }
            @media (max-width: 576px) {
                line-height: unset;
            }
        }
    }
}


.num-draws {
    .active-tab {
        border-bottom: 4px solid red; /* Adjust the color and size as needed */
        a {
            color: red;
            border: none;
        }
      }

      li {
        line-height: 80px;
        width: 200px;
        text-align: center;

        @media (max-width: 576px) {
            width: unset;
            line-height: 40px;
        }

        a {
            font-size: 18px;
            font-weight: 700;
            &.nav-link {
                padding: unset !important;
            }
            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    li:not(.active-tab) {
        a {
            color: #666;
            border: none;
            &:hover {
                color: #666;
                border: none;
            }
        }
    } 
}

.drawing-date {
    line-height: 35px;
}

.number-dates {
    line-height: 35px;
    font-size: 14px;
}

.dates-dropdown {
    padding: 5px 25px 5px 8px;        
}

.lottery-number {
    span {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 700;
        margin: 0 10px;
        color: #fff;
        background: red;
    }
}

.reward-label {
    margin-top: 20px;
    margin-bottom: 5px;
    color: #666;
    padding-left: 10px;
}

.reward-list {
    margin: 0 10px;

    .reward-info {
        span {
            width: 48px;
            height: 48px;
            line-height: 44px;
            text-align: center;
            border-radius: 50%;
            font-size: 24px;
            font-weight: 700;
            margin-right: 10px;
            color: #333;
            border: 2px solid #ccc;
        }
        &.small-ball {
            span {
                width: 40px;
                height: 40px;
                font-size: 20px;
                font-weight: 500;
                line-height: 36px;
                margin-bottom: 10px;
            }
        }
    }
}

.number-just {
    margin: 0 10px;

    .number-ball {
        font-size: 20px;
        line-height: 24px;
        display: flex;
    }
}